@import "variables";
@import "bootstrap/scss/bootstrap";

/* change the background color */
.navbar-custom {
    background-color: map-get($theme-colors, primary);
    font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir, helvetica, "helvetica neue", ubuntu, roboto, noto, "segoe ui", arial, sans-serif;
    font-weight: bold;
}
/* change the brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
    color: white;
}
/* change the link color */
.navbar-custom .navbar-nav .nav-link {
    color: white;
}
/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:hover .nav-link {
    color: white;
}

.navbar-custom .nav-item:hover {
    background-color: rgba(0,0,0,0.3);
}

.navbar-custom .active {
    background-color: rgba(0,0,0,0.2);
}

h1 {
    font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir, helvetica, "helvetica neue", ubuntu, roboto, noto, "segoe ui", arial, sans-serif;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: .2rem;
}

h2,h3,h4 {
    font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir, helvetica, "helvetica neue", ubuntu, roboto, noto, "segoe ui", arial, sans-serif;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: .2rem;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir, helvetica, "helvetica neue", ubuntu, roboto, noto, "segoe ui", arial, sans-serif;
    font-size: 19px;
}

table {
    font-size: 1em;
}

time {
    font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir, helvetica, "helvetica neue", ubuntu, roboto, noto, "segoe ui", arial, sans-serif;
    font-size: 1em;
}

img.banner {
    margin-bottom: 20px;
    width: 100%;
}

div.post-thumbnail {
    float:left;
    width:196px;
    margin-top: 10px;
    margin-right:10px;
}

.post-thumbnail img {
    width: 100%;
}

pre, code {
    font-family: 'San Francisco Mono', Monaco, "Consolas", "Lucida Console", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", monospace;
    background-color: $code-bg;
}

.sidebar-avatar {
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
}

time.published {
    font-size: 0.9rem;
    line-height: 2rem;
}

nav {
}

li{
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
}

.inline-block {
    display: inline-block;
}

.footer {
    color: white;
    background-color: map-get($theme-colors, primary);
    margin-top: 1.6rem;
    padding-top: 0.7rem;
    padding-right: 3.5rem;
}

.site-banner {
    position: relative;
}

.site-banner-title {
    position: absolute;
    top: 1.5rem;
    left: 3rem;
    background-color: rgba(0,0,0,0.2);
    color: white;
}

.footer-link {
    color: white;
}

.footer-link:hover {
    color: white;
    text-decoration-line: underline;
}

blockquote {
    margin: 0;
    padding-block: 0;
    padding-inline-end: 0;
    padding-inline-start: 1.5rem;
    border-inline-start: 1px solid map-get($theme-colors, light);
}
