/* custom vars */
$blue-0: #deeefc;
$blue-1: #7eb1dc;
$blue-2: #5393c8 ;
$blue-3: #337bb7;
$blue-4: #1266ab;
$blue-5: #094c83;
$blue-6: #112D6A;

$yellow-1: #ffe28a;
$yellow-2: #ffd85f;
$yellow-3: #ffcf3a;
$yellow-4: #ffc30b;
$yellow-5: #ca9800;

$red-1: #ffadba;
$red-2: #ff8f5f;
$red-3: #ff753a;
$red-4: #ff540b;
$red-5: #ca3c00;

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$theme-colors: (
  primary: #5469C9,
  secondary: #4f4f4f,
  success: #198754,
  info: #0dcaf0,
  warning: #ffc107,
  danger: #dc3545,
  light: #c2c2c2,
  dark: #292929
);

$navbar-dark-color:                 white;
$navbar-dark-hover-color:           rgba(0,0,0,0.3);
$navbar-dark-active-color:          rgba(0,0,0,0.2);
$navbar-padding-y:                  0px;

$code-color:                  black;
$code-bg:                     $gray-100;

$pre-color:                   black;
$pre-border-color:            $blue-3;

$link-color: map-get($theme-colors, primary);
$link-decoration: none;
